import React from 'react'
import PropTypes from 'prop-types'
import '../../../sass/Common/ValidationErrorMessage.scss'

function ValidationErrorMessage ({ className, text }) {
  if (!text) {
    return null
  }
  return (
    <div className={`validation-error-message ${className}`}>
      <div className='validation-error-message__popup'>
        {text}
      </div>
    </div>
  )
}

ValidationErrorMessage.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired
}

ValidationErrorMessage.defaultProps = {
  className: ''
}

export default ValidationErrorMessage
