import React from 'react'
import PropTypes from 'prop-types'
import LanguageSwitcher from '../LanguageSwitcher'
import './PublicPageLayout.scss'

function PublicPageLayout ({ children, clientSideProps, containerClassName }) {
  return (
    <div className='public-page-layout'>
      <div className='public-page-layout__mobile-logo-background'>
        <div className={`public-page-layout__mobile-logo__${clientSideProps.language}`} />
      </div>
      <div className='public-page-layout__body'>
        <div className='public-page-layout__image-wrapper'>
          <div className='public-page-layout__image' />
        </div>
        <div className='public-page-layout__section'>
          <div className={`public-page-layout__desktop-logo__${clientSideProps.language}`} />
          <div className={`public-page-layout__content ${containerClassName}`}>
            {children}
          </div>
          <LanguageSwitcher
            className='public-page-layout__language-switcher'
            currLanguage={clientSideProps.language}
            supportedLanguages={clientSideProps.supportedLanguages}
          />
        </div>
      </div>
    </div>
  )
}

PublicPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  clientSideProps: PropTypes.object.isRequired,
  containerClassName: PropTypes.string
}

PublicPageLayout.defaultProps = {
  containerClassName: ''
}

export default PublicPageLayout
