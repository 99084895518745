import React from 'react'
import PropTypes from 'prop-types'
import analytics from '../../../helpers/analytics/analytics'

function WithAnalytics (El) {
  function ElWithAnalytics (props) {
    const elProps = Object.assign({}, props)
    delete elProps.analyticsCategory
    delete elProps.analyticsLabel

    const sendAnalytics = (e) => {
      if (!props.disabled) {
        analytics.trackClick(props.analyticsCategory, props.analyticsLabel)
      }
      if (props.onClick) {
        props.onClick(e)
      }
    }

    return (
      <El {...elProps} onClick={sendAnalytics} />
    )
  }

  ElWithAnalytics.propTypes = {
    analyticsCategory: PropTypes.string.isRequired,
    analyticsLabel: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
  }

  return ElWithAnalytics
}

export default WithAnalytics
