import React from 'react'
import PropTypes from 'prop-types'
import SVGArrowRight from '../SVG/SVGArrowRight'
import AnchorWithAnalytics from '../Analytics/AnchorWithAnalytics'
import ButtonWithAnalytics from '../Analytics/ButtonWithAnalytics'
import './Button.scss'
const baseClass = 'button'

function Button (props) {
  const {
    alignIconLeft,
    className,
    disabled,
    displayIcon,
    href,
    Icon,
    onClick,
    outlined,
    secondary,
    transparent,
    text
  } = props
  const disabledClass = disabled ? `${baseClass}--disabled` : ''
  const secondaryClass = secondary ? `${baseClass}--secondary` : ''
  const transparentClass = transparent ? `${baseClass}--transparent` : ''
  const outlinedClass = outlined ? `${baseClass}--outlined` : ''
  const leftAlignedIconClass = alignIconLeft ? `${baseClass}--icon-left` : ''
  function handleOnClick (e) {
    if (!disabled && typeof onClick === 'function') {
      onClick(e)
    }
  }
  const elProps = Object.assign({}, props)
  delete elProps.className
  delete elProps.Icon
  delete elProps.secondary
  delete elProps.text
  delete elProps.displayIcon
  delete elProps.onClick
  delete elProps.transparent
  delete elProps.outlined
  delete elProps.alignIconLeft
  const ButtonElement = href ? AnchorWithAnalytics : ButtonWithAnalytics

  return (
    <ButtonElement
      {...elProps}
      className={`${baseClass} ${disabledClass} ${secondaryClass} ${transparentClass} ${outlinedClass} ${leftAlignedIconClass} ${className}`}
      onClick={handleOnClick}
    >
      <span className={`${baseClass}__text`}>{text}</span>
      {displayIcon && <Icon className={`${baseClass}__icon`} />}
    </ButtonElement>
  )
}

Button.propTypes = {
  alignIconLeft: PropTypes.bool,
  analyticsCategory: PropTypes.string.isRequired,
  analyticsLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  displayIcon: PropTypes.bool,
  href: PropTypes.string,
  Icon: PropTypes.func,
  onClick: PropTypes.func,
  outlined: PropTypes.bool,
  secondary: PropTypes.bool,
  text: PropTypes.string.isRequired,
  transparent: PropTypes.bool
}

Button.defaultProps = {
  alignIconLeft: false,
  className: '',
  disabled: false,
  displayIcon: true,
  Icon: SVGArrowRight,
  outlined: false,
  secondary: false,
  transparent: false
}

export default Button
