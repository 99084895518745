import analyticsProviders from './analyticsProviders'

function trackClick (category, label) {
  if (!category || !label) {
    return
  }
  analyticsProviders.forEach((analyticsProvider) => {
    typeof analyticsProvider.trackClick === 'function' &&
      analyticsProvider.trackClick(category, label)
  })
}

export default { trackClick }
