function googleAnalytics (googleAnalytics, client) {
  const googleAnalyticsFunction = typeof googleAnalytics === 'function'
    ? googleAnalytics
    : () => {}

  function trackClick (eventCategory, eventLabel) {
    const trackingOptions = {
      hitType: 'event',
      eventCategory,
      eventAction: 'click',
      eventLabel
    }
    googleAnalyticsFunction('send', trackingOptions)
    googleAnalyticsFunction(`${client}.send`, trackingOptions)
  }

  return { trackClick }
}

export default googleAnalytics
