import PropTypes from 'prop-types'
import React from 'react'
import '../../sass/contact.scss'

/* eslint react/no-danger: 0 */
function ContactUs ({ dict, classNameBase }) {
  return (
    <div className={`${classNameBase}__contact contact`}>
      <strong className={`${classNameBase}__contact-title contact-title`}>{dict.get('havingTrouble')}</strong>
      <div dangerouslySetInnerHTML={{ __html: dict.get('contactInfo') }} />
    </div>
  )
}

ContactUs.propTypes = {
  classNameBase: PropTypes.string.isRequired,
  dict: PropTypes.object.isRequired
}

export default ContactUs
