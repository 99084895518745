import React from 'react'
import ReactDOM from 'react-dom'
import Login from './components/Login'
import ContactUs from './components/ContactUs'
import MaintenancePeriodAlert from './components/MaintenancePeriodAlert'
import clientDictionary from 'clientDictionary'
import oxford from 'oxford'
import errorsGenerator from '../validation/errors'
import clientSideProps from 'clientSideProps'
import PublicPageLayout from './components/PublicPageLayout/PublicPageLayout'
import '../sass/loginPage.scss'
import _ from 'lodash'

const TIRESCORE_CLIENT_CODES = ['CMUST', 'CCUST', 'CMCAT']
const errors = errorsGenerator(oxford(clientDictionary.validationErrors))
const clientCode = clientSideProps.client
const enableCooperTiresNamesInfoMessage = clientSideProps.features.enableCooperTiresNamesInfoMessage
const enableTemporaryEmailAddressInfoMessage = clientSideProps.features.enableTemporaryEmailAddressInfoMessage
const urls = {
  reregistration: '/dealer/reregistration',
  forgotPassword: '/forgotPassword',
  helpPhone: '1-888-555-5555',
  helpEmail: 'helpme@coopertires.com'
}
const dict = oxford(clientDictionary.loginWrapper)
const dictLogin = oxford(clientDictionary.login)

const cmcaUrl = `https://${clientSideProps.clientToHostMap.CMCA}/login`
const cmcaLink = (clientCode === 'CMUS'
  ? (
    <div className='login-page__redirect'>
      <img src='/img/canadian-flag-icon.png' />
      <span dangerouslySetInnerHTML={{ __html: dict.get('cmcaRedirect', cmcaUrl) }} />
    </div>
    )
  : null
)
const cmcatUrl = `https://${clientSideProps.clientToHostMap.CMCAT}/login`
const cmcatLink = (clientCode === 'CMUST'
  ? <span className='login-page__redirect' dangerouslySetInnerHTML={{ __html: dict.get('cmcatRedirect', cmcatUrl) }} />
  : null
)
const registrationLink = !TIRESCORE_CLIENT_CODES.includes(clientCode)
  ? (
    <strong>
      <p dangerouslySetInnerHTML={{ __html: dict.get('registration', urls.reregistration) }} />
    </strong>
    )
  : null

const loginPage = (
  <PublicPageLayout
    clientSideProps={clientSideProps}
    containerClassName='login-container'
  >
    {
      clientSideProps.maintenance.enable
        ? <MaintenancePeriodAlert classNameBase='login-page' message={dict.get('maintenancePeriodMessage')} />
        : clientSideProps.maintenance.showPreOutagePeriodAlert
          ? <MaintenancePeriodAlert classNameBase='login-page' message={dict.get('maintenancePreOutageMessage')} />
          : null
    }
    {
      enableCooperTiresNamesInfoMessage.active && _.includes(enableCooperTiresNamesInfoMessage.allowedClients, clientCode)
        ? <MaintenancePeriodAlert classNameBase='login-page' message={dict.get('cooperTiresNamesInfoMessage')} />
        : null
    }
    {
      enableTemporaryEmailAddressInfoMessage.active && _.includes(enableTemporaryEmailAddressInfoMessage.allowedClients, clientCode)
        ? <MaintenancePeriodAlert classNameBase='login-page' message={dict.get('temporaryEmailAddressInfoMessage')} />
        : null
    }
    <Login dict={dictLogin} errors={errors} />
    <div className='login__forgot-password'>
      <a href={urls.forgotPassword}>
        {dict.get('forgotPassword')}
      </a>
    </div>
    <ContactUs classNameBase='login-page' dict={dict} />
    {registrationLink}
    {cmcaLink}
    {cmcatLink}
  </PublicPageLayout>
)

/* eslint react/no-danger: 0 */
ReactDOM.render(loginPage, document.getElementById('app'))
