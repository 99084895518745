import PropTypes from 'prop-types'
import React from 'react'
import '../../../../sass/SVG.scss'

const baseClass = 'icon'

function SVGHOC (SVGPaths, iconClass, viewBox = '0 0 24 24') {
  function SVG (props) {
    const { className } = props
    return (
      <svg role='img' className={`${baseClass} ${baseClass}-${iconClass} ${className}`} focusable='false' viewBox={viewBox} xmlns='http://www.w3.org/2000/svg'>
        <SVGPaths {...props} />
      </svg>
    )
  }

  SVG.propTypes = {
    className: PropTypes.string
  }
  SVG.defaultProps = {
    className: ''
  }

  return SVG
}

export default SVGHOC
