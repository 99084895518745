import PropTypes from 'prop-types'
import React from 'react'
import Form from 'formsy-react'
import axios from 'axios'
import Input from './Input'
import Button from './Common/Button/Button'
import '../../sass/login.scss'
import ValidationErrorMessage from './Common/ValidationErrorMessage'
import { emailRegexp } from '../../../common/regexExpressions'

class Login extends React.Component {
  /* istanbul ignore next */
  constructor (props) {
    super(props)
    this.state = {
      canSubmit: false,
      err: null,
      customValidationErrors: {}
    }
    this.handleFormValid = this.handleFormValid.bind(this)
    this.handleFormInvalid = this.handleFormInvalid.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setCustomValidationError = this.setCustomValidationError.bind(this)
    this.getCustomValidationError = this.getCustomValidationError.bind(this)
  }

  handleFormValid () {
    this.setState({ canSubmit: true, err: null })
  }

  handleFormInvalid () {
    this.setState({ canSubmit: false })
  }

  handleErrors (err) {
    const serverError = this.props.dict.get('serverError')
    if (err.response.status === 401) {
      this.setState({ err: err.response.data.errors[0] })
    } else {
      this.handleFormValid()
      this.setState({ err: serverError })
    }
  }

  handleSubmit (data) {
    this.handleFormInvalid()
    const submitData = {
      username: data.email.trim(),
      password: data.password.trim()
    }
    return axios.post('/login', submitData, {
      responseType: 'json'
    })
      .then((response) => (window.location.pathname = response.data.redirectUrl))
      .catch((err) => this.handleErrors(err))
  }

  setCustomValidationError (name, error) {
    this.setState({ customValidationErrors: Object.assign({}, this.state.customValidationErrors, { [name]: error }) })
  }

  getCustomValidationError (name) {
    return this.state.customValidationErrors[name]
  }

  render () {
    const { dict, errors } = this.props
    // hidden disabled inputs to fix firefox's autopopulation of field
    return (
      <div className='login__display-inline-block'>
        <Form className='login' onInvalid={this.handleFormInvalid} onSubmit={this.handleSubmit} onValid={this.handleFormValid}>
          <Input
            classNameBase='login'
            customValidationError={this.getCustomValidationError('email')}
            label={dict.get('emailLabel')}
            name='email'
            required
            setCustomValidationError={this.setCustomValidationError}
            validationErrors={{
              matchRegexp: dict.get('emailError'),
              required: errors.email.required
            }}
            validations={{
              matchRegexp: emailRegexp
            }}
          />
          <Input
            classNameBase='hidden'
            disabled
            label={dict.get('emailLabel')}
            name='email_hidden'
            required={false}
            validationErrors={{
              matchRegexp: this.props.dict.get('emailError')
            }}
            validations={{
              matchRegexp: emailRegexp
            }}
          />
          <Input
            classNameBase='hidden'
            disabled
            id='password_input'
            label={dict.get('passwordLabel')}
            name='password_hidden'
            required={false}
            type='password'
          />
          <Input
            classNameBase='login'
            customValidationError={this.getCustomValidationError('password')}
            id='password_input'
            label={dict.get('passwordLabel')}
            name='password'
            required
            setCustomValidationError={this.setCustomValidationError}
            type='password'
          />
          <ValidationErrorMessage text={this.state.err} />
          <div className='buttons'>
            <Button
              analyticsCategory='Button'
              analyticsLabel='Login'
              className='login__submit'
              disabled={!this.state.canSubmit}
              displayIcon={false}
              text={dict.get('submitBtnLabel')}
              type='submit'
            />
          </div>
        </Form>
      </div>
    )
  }
}

Login.propTypes = {
  dict: PropTypes.object.isRequired,
  errors: PropTypes.object
}

export default Login
