import PropTypes from 'prop-types'
import React from 'react'
import '../../sass/contact.scss'

/* eslint react/no-danger: 0 */
function MaintenancePeriodAlert ({ classNameBase, message }) {
  return (
    <div className={`${classNameBase}__info-message`}>
      <div className='alert alert-warning' role='alert'>
        <p>
          <span className='info-message__icon glyphicon glyphicon-info-sign' />{' '}
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </p>
      </div>
    </div>
  )
}

MaintenancePeriodAlert.propTypes = {
  classNameBase: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

export default MaintenancePeriodAlert
