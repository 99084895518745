'use strict'

module.exports = (dict) => {
  return {
    email: {
      maxLength: dict.get('email.maxLength'),
      matchRegexp: dict.get('email.matchRegexp'),
      isEmail: dict.get('email.matchRegexp'),
      required: dict.get('email.empty'),
      field: 'dealerOwner.email'
    },
    firstName: {
      required: dict.get('firstName.empty'),
      maxLength: dict.get('firstName.maxLength'),
      field: 'dealerOwner.firstName'
    },
    lastName: {
      required: dict.get('lastName.empty'),
      maxLength: dict.get('lastName.maxLength'),
      field: 'dealerOwner.lastName'
    },
    phoneNumber: {
      matchRegexp: dict.get('phoneNumber.matchRegexp'),
      required: dict.get('phoneNumber.empty'),
      field: 'dealerOwner.phoneNumber'
    },
    fax: {
      matchRegexp: dict.get('fax.matchRegexp'),
      field: 'dealerOwner.address.fax'
    },
    niadaMember: {
      maxLength: dict.get('niadaMember.maxLength')
    },
    address1: {
      maxLength: dict.get('address1.maxLength'),
      required: dict.get('address1.empty'),
      field: 'dealerOwner.address.address1'
    },
    city: {
      maxLength: dict.get('city.maxLength'),
      required: dict.get('city.empty'),
      field: 'dealerOwner.address.city'
    },
    state: {
      required: dict.get('state.empty'),
      field: 'dealerOwner.address.state'
    },
    province: {
      required: dict.get('province.empty'),
      field: 'dealerOwner.address.state'
    },
    zip_code_cmus: {
      matchRegexp: dict.get('zip_code_cmus.matchRegexp'),
      minLength: dict.get('zip_code_cmus.minLength'),
      required: dict.get('zip_code_cmus.empty'),
      field: 'dealerOwner.address.zip'
    },
    zip_code_cmca: {
      matchRegexp: dict.get('zip_code_cmca.matchRegexp'),
      required: dict.get('zip_code_cmca.empty'),
      field: 'dealerOwner.address.zip'
    },
    companyName: {
      maxLength: dict.get('companyName.maxLength'),
      required: dict.get('companyName.empty'),
      field: 'account.companyName',
      deleteFields: ['firstName_company', 'lastName_company']
    },
    firstName_company: {
      required: dict.get('firstName_company.empty'),
      maxLength: dict.get('firstName_company.maxLength'),
      field: 'account.firstName',
      deleteFields: ['companyName']
    },
    lastName_company: {
      required: dict.get('lastName_company.empty'),
      maxLength: dict.get('lastName_company.maxLength'),
      field: 'account.lastName',
      deleteFields: ['companyName']
    },
    ssn: {
      matchRegexp: dict.get('ssn.matchRegexp'),
      required: dict.get('ssn.empty'),
      field: 'dealerOwner.ssn'
    },
    sin: {
      matchRegexp: dict.get('sin.matchRegexp'),
      required: dict.get('sin.empty'),
      field: 'dealerOwner.ssn'
    },
    fein: {
      matchRegexp: dict.get('fein.matchRegexp'),
      required: dict.get('fein.empty'),
      field: 'account.fein'
    },
    hst: {
      matchRegexp: dict.get('hst.matchRegexp'),
      maxLength: dict.get('hst.maxLength'),
      minLength: dict.get('hst.minLength'),
      required: dict.get('hst.empty'),
      field: 'account.fein'
    },
    password: {
      matchRegexp: dict.get('password.matchRegexp'),
      required: dict.get('password.empty'),
      field: 'dealerOwner.password'
    },
    confirmationPassword: {
      equalsField: dict.get('confirmationPassword.equalsField'),
      required: dict.get('confirmationPassword.empty'),
      field: 'dealerOwner.confirmPassword'
    },
    accountNumber: {
      maxLength: dict.get('accountNumber.maxLength'),
      minLength: dict.get('accountNumber.minLength'),
      required: dict.get('accountNumber.empty'),
      field: 'account.accountNo'
    },
    routingNumber: {
      maxLength: dict.get('routingNumber.maxLength'),
      minLength: dict.get('routingNumber.minLength'),
      required: dict.get('routingNumber.empty'),
      field: 'account.routingNo'
    },
    dateOfBirth: {
      matchRegexp: dict.get('dateOfBirth.matchRegexp'),
      required: dict.get('dateOfBirth.empty'),
      field: 'dealerOwner.dateOfBirth'
    },
    account_phoneNumber: {
      matchRegexp: dict.get('account_phoneNumber.matchRegexp'),
      required: dict.get('account_phoneNumber.empty'),
      field: 'account.phoneNumber'
    },
    uploadVoidCheck: {
      empty: dict.get('uploadVoidCheck.empty'),
      field: 'uploadVoidCheck'
    },
    checkbox: {
      empty: dict.get('checkbox.empty')
    },
    voidcheckbox: {
      empty: dict.get('voidcheckbox.empty')
    }
  }
}
