import React from 'react'
import SVG from './SVG'

function SVGArrowRight () {
  return (
    <path d='M10,7l5,5,-5,5Z' />
  )
}

export default SVG(
  SVGArrowRight,
  'arrowDown'
)
export { SVGArrowRight }
