import React from 'react'
import PropTypes from 'prop-types'
import '../../sass/LanguageSwitcher.scss'

const LANG_LOCALIZED = {
  en: 'English',
  fr: 'Français',
  es: 'Español'
}

class LanguageSwitcher extends React.Component {
  /* istanbul ignore next */
  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.getNextLanguage = this.getNextLanguage.bind(this)
    this.state = { nextLanguage: this.getNextLanguage(props.currLanguage) }
  }

  setLanguageCookie (lang) {
    const date = new Date()
    date.setDate(date.getDate() + 365)
    document.cookie = `lang=${lang};expires=${date.toGMTString()};path=/`
    window.location.reload()
  }

  getNextLanguage (currLanguage) {
    const supportedLanguages = this.props.supportedLanguages
    const currLanguageIndex = supportedLanguages.indexOf(currLanguage)

    let nextLanguageIndex = currLanguageIndex + 1
    if (nextLanguageIndex >= supportedLanguages.length) {
      nextLanguageIndex = 0
    }

    const nextLanguage = supportedLanguages[nextLanguageIndex]
    return nextLanguage
  }

  handleClick (currLanguage) {
    this.setLanguageCookie(currLanguage)
    const nextLanguage = this.getNextLanguage(currLanguage)
    this.setState({ nextLanguage })
  }

  render () {
    if (this.props.supportedLanguages.length <= 1) {
      return null
    }

    const lang = this.state.nextLanguage
    const langName = LANG_LOCALIZED[lang]
    const handler = this.handleClick.bind(null, lang)

    return (
      <div className={`lang-switch ${this.props.className}`}>
        <a className='lang-switch__lang' onClick={handler}>{langName.toUpperCase()}</a>
      </div>
    )
  }
}

LanguageSwitcher.propTypes = {
  className: PropTypes.string,
  currLanguage: PropTypes.string.isRequired,
  supportedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired
}

LanguageSwitcher.defaultProps = {
  className: ''
}

export default LanguageSwitcher
